import React from "react"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Listing from "./listing"
import styles from "./listingContainer.module.css"
import moment from 'moment';
import AppConfig from '../appconfig';


export default class ListingContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			mediaSelected: true, 
			toolsSelected: true,
			otherSelected: true,
			listings: "",
			showModal: false,
			modalTitle: '',
			modalDescription: '',
			modalExpires: '',
			modalEmail: '',
			modalCategory: '',
			modalPrice: ''
		};

		this.handleChecked = this.handleChecked.bind(this);
		this.getListings = this.getListings.bind(this);
	}

	componentDidMount() {
		this.getListings();
	}

	getListings() {
		let userId = localStorage.getItem('userId');
		let sessionId = localStorage.getItem('sessionId');

		let url = AppConfig.API_URL + 'getListings';

		url += ('/' + userId);
		url += ('/' + sessionId);

	 	fetch(url)
		.then(res => res.json())
		.then(
			result => {
				if (result.success) {
					var listing;
					this.setState({
						listings: result.listings
					});

					Object.keys(result.listings).forEach(function(key) {
						listing = result.listings[key];
					});
				} else {
					console.log("Error");
				}
			},
			error => {
				console.log("Error");
			}
		);

		return false;
	}


	handleChecked(checkbox) {
		
		switch (checkbox.target.value) {

			case "media":
				this.setState({
					mediaSelected: !this.state.mediaSelected
				});
				break;

			case "tools":
				this.setState({
					toolsSelected: !this.state.toolsSelected
				});
				break;

			case "other":
				this.setState({
					otherSelected: !this.state.otherSelected
				});
				break;
		}
 	}

 	toggleShowModal(title, description, expires, email, category, price) {
 		if (this.state.showModal) {
 			// Modal has been shown, hide it.

 			this.setState({
				showModal: !this.state.showModal,
				modalTitle: '',
				modalDescription: '',
				modalExpires: '',
				modalEmail: '',
				modalCategory: '',
				modalPrice: ''
			});

 		} else {
 			// Modal has been hidden, show it.
 			this.setState({
				showModal: !this.state.showModal,
				modalTitle: title,
				modalDescription: description,
				modalExpires: expires,
				modalEmail: email,
				modalCategory: category,
				modalPrice: price
			});
 		}
 	}

 	render() {
 		var listings = [];
 		if (this.state.listings != undefined) {
 			for (var i = 0; i < this.state.listings.length; i++) {
	 			let current = this.state.listings[i];
	 			let categoryState = this.state.otherSelected;

	 			if (current.category == 'Media') {
	 				categoryState = this.state.mediaSelected;
	 			} else if (current.category == 'Tools') {
	 				categoryState = this.state.toolsSelected;
	 			}
	 			
				listings.push(

						<Row key={i} onClick={ () => this.toggleShowModal(current.title, current.description, current.expires, current.email, current.category, current.price) }>
			            	<Col>
			                	<Listing
			                		display={categoryState} category={current.category} title={current.title} description={current.description} expires={current.expires} 
			                		price={current.price} />
			            	</Col>
			        	</Row>
				);
			}
 		}
 		

		return (
			<div>
				
				<Row>
		            <Col>
			            <div className={styles.filterwrapper}>

							<h3>Subscriptions currently for sale</h3>

			                <div className={styles.filter}>
			                    <label className={styles.checkboxcontainer}>Media
			                        <input type="checkbox" value="media" checked={this.state.mediaSelected} onChange={this.handleChecked} />
			                        <span className={styles.checkmark}></span>
			                    </label>

			                    <label className={styles.checkboxcontainer}>Tools
			                        <input type="checkbox" value="tools" checked={this.state.toolsSelected} onChange={this.handleChecked} />
			                        <span className={styles.checkmark}></span>
			                    </label>

			                    <label className={styles.checkboxcontainer}>Other
			                        <input type="checkbox"  value="other" checked={this.state.otherSelected} onChange={this.handleChecked} />
			                        <span className={styles.checkmark}></span>
			                    </label>

			                </div>

		           	 	</div>


		            </Col>
	        	</Row>

	        	{listings}

		       <ListingModal
					show={this.state.showModal}
					onHide={() => this.toggleShowModal()}
					title={this.state.modalTitle}
					description={this.state.modalDescription}
					expires={this.state.modalExpires}
					email={this.state.modalEmail}
					category={this.state.modalCategory}
					price={this.state.modalPrice}
				/>

			</div>
		);
	}
}


function ListingModal(props) {

	const mail = () => {
		if (props.email == undefined || props.email == '') {
			document.getElementById("sign-in-button").click();
			props.onHide();
		} else {
			window.location.href = "mailto:"+props.email+'?cc='+""+'&subject=Regarding '+props.title+'&body='+"";
		}
	}

	let expires = moment(props.expires);
	
	return (
		
		<Modal
			{...props}
			dialogClassName="modal-40w"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>

			<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						{props.title}
					</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="listing-module-expire">
					Expires: {expires.format("Do MMMM YYYY")}
				</div>
				<div className="listing-module-category">
					Category: {props.category}
				</div>

				<div className="listing-module-desc">
					{props.description}
				</div>
				
				<div className="listing-module-price">
					Price: ${props.price}
				</div>

			</Modal.Body>
			<Modal.Footer style={{justifyContent: "space-between"}}>
				<Button variant="outline-primary" onClick={props.onHide}>Close</Button>
				<Button variant="outline-primary" onClick={mail}>Contact seller</Button>
			</Modal.Footer>
		</Modal>
	);
}