import React from "react"
import AdSense from 'react-adsense';
import { graphql } from "gatsby"

import SEO from "../components/seo"
import ListingContainer from "../components/listingContainer"
import Container from 'react-bootstrap/Container'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'



const Index = ({ data }) => {
	const siteTitle = data.site.siteMetadata.title

	return (
		
		<div className="frontPageWrapper">
				<SEO 
					title="Home" 
					description="Flip your unwanted subscriptions here. Sell or buy cheap limited subscriptions for any streaming services or tools."
					pagePath="/"
				/>

				<header className="masthead">
					<div className="mastheadContent">
						<div className="container">
							<h1 className="mastheadHeading mb-0">Flip your unwanted subscriptions here</h1>
							<h2 className="mastheadSubheading mb-0"></h2>
							<Link className="btn btn-primary btn-xl rounded-pill mt-5" to="/about">Learn More</Link>
						</div>
					</div>
					<div className="bg-circle-1 bg-circle"></div>
					<div className="bg-circle-2 bg-circle"></div>
					<div className="bg-circle-3 bg-circle"></div>
					<div className="bg-circle-4 bg-circle"></div>
				</header>

				<p style={{ margin: '1em', fontSize: '1.5em', fontWeight: '800' }}>
					Only need a subscription for a limited time? <br /> Get a cheap subscription by anyone selling here
				</p>

				<div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon">
						<FontAwesomeIcon icon={faStar} />
					</div>
                    <div className="divider-custom-line"></div>
                </div>

			<Container>
				<ListingContainer />
			</Container>

			<AdSense.Google
				client='ca-pub-3494881483972444'
				slot='4037660899'
				style={{ display: 'block' }}
				format='auto'
				responsive='true'
			/>
		</div>
	)
}

export default Index

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
	}
`