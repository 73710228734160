import React from "react"
import styles from "./listing.module.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { faWrench } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import Row from 'react-bootstrap/Row'



export default function Listing(props) {
	let icon = faCircle;
	if (props.category == 'Media') {
		icon = faPlayCircle;
	} else if (props.category == 'Tools') {
		icon = faWrench;
	}
	

	let expiresText;

	let currentTime = new Date().getTime();
	if (currentTime > props.expires) {
		expiresText = <div className={styles.listingexpired}>Expired</div>;
	} else {
		let diffDate = new Date(props.expires - currentTime);

		let years = diffDate.getUTCFullYear() - 1970;
		let months = diffDate.getUTCMonth();
		let days = diffDate.getUTCDate()-1;
		if (years > 0) {
			expiresText = years + ' years'
		} else if (months > 0) {
			expiresText = months;
			expiresText += months == 1 ? ' month' : ' months'
		} else if (days > 0) {
			expiresText = days;
			expiresText += days == 1 ? ' day' : ' days'
		} else {
			expiresText = <div className={styles.listingexpired}>Expired</div>;
		}
	}

	

	return (
		<Row className={props.display ? styles.listingwrapper : styles.hidden}>
			<div className={styles.listingcol1}>
				<div className={styles.listingicon}><div className={"listing" + icon.iconName}><FontAwesomeIcon style={{width: '2em', height: '2em'}} icon={icon} /></div></div>
			</div>
			<div className={styles.listingcontent}>
				<h4 className={styles.listingtitle}>{props.title}</h4>
				<div className={styles.listingdescription}>{props.description}</div>
			</div>
			<div className={styles.listingcol3}>
				<div className={styles.listingpricewrapper}><span className={styles.listingprice}>${props.price}</span></div>
				<div className={styles.listingexpireswrapper}>Expires in <br /> <span className={styles.listingexpires}>{expiresText}</span></div>
			</div>
		</Row>
	);
}
